<template>
  <div>
    <!-- 视频维护 -->

    <Head />
    <div class="main">
      <div class="content">
        <!-- 左侧类别选择区域 -->

        <div class="left—Video">
          <!-- 分类标题 -->
          <div class="vedio-title" @click="allvedio">
            <img src="../../assets/jingtaiye-pic/video.svg" alt="">
            <span>{{ $fanyi('视频介绍') }}</span>
          </div>

          <div class="item" @click="switchvedio(item, ind)" v-for="(item, ind) in titlelist " :key="item.id"
            :class="{ ativeitem: index == ind }">
            {{ item.english_name }}
            <span></span>
          </div>

        </div>
        <!-- 右侧视频区域 -->
        <div class="right-box">

          <!-- 一级分类标签 -->
          <div class="item" v-for="(item) in  list  " :key="item.id">
            <div class="top-title">{{ item.english_name }}</div>
            <!-- {{ item.video_list.length > 0 }} -->
            <!--  二级分类有用 -->
            <div v-if="item.video_list.length == 0">
              <div class="listitem" v-for="ite in  item.video_list.length > 0 ? item.video_list : item.class_mark "
                :key="ite.id">
                <div class="listitem-title" v-if="item.video_list.length == 0">{{ ite.english_name }}</div>
                <!-- 二级 -->
                <div class="vediolist">
                  <div class="vedioitem" @mouseover="play(i)" v-for="(i, index) in  ite.video_list" :key="index">
                    <!-- 视频 -->
                    <div class="vid-box">
                      <iframe ref="iframe" class="bigVideo" @load="onload($event, i)" :src="i.url" frameborder="0"
                        allowfullscreen></iframe>
                      <div class="loading" v-if="i.load">
                        <div class="loader"></div>
                      </div>
                    </div>
                    <!--标题  -->
                    <div class="text">{{ i.video_title }}</div>
                    <div class="footer">
                      <div class="left-pic">
                        <img src="../../assets/jingtaiye-pic/frequency.svg" alt="">


                        <div><span>{{ i.click_num }}</span>frequency</div>

                      </div>
                      <div class="right">
                        {{ i.created_at }}
                      </div>


                    </div>
                  </div>

                </div>


              </div>

            </div>
            <!-- 一级 -->
            <div v-else>


              <div class="vediolist">

                <div class="vedioitem" @mouseover="play(i)" v-for="(i, index) in  item.video_list" :key="index">
                  <!-- 视频 -->
                  <div class="vid-box">
                    <iframe ref="iframe" class="bigVideo" @load="onload($event, i)" :src="i.url" frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen></iframe>
                    <div class="loading" v-if="i.load">
                      <div class="loader"></div>
                    </div>

                  </div>

                  <!--标题  -->
                  <div class="text">{{ i.video_title }}</div>
                  <div class="footer">
                    <div class="left-pic">
                      <img src="../../assets/jingtaiye-pic/frequency.svg" alt="">


                      <div><span>{{ i.click_num }}</span>frequency</div>

                    </div>
                    <div class="right">
                      {{ i.created_at }}
                    </div>


                  </div>


                </div>


              </div>

            </div>

          </div>

        </div>



      </div>

    </div>
    <Foot />
  </div>
</template>
<script>
import Foot from "../../components/foot/Foot.vue";
import Head from "../../components/head/HomePageTop.vue";
import Dialog from '../../components/public/Dialog.vue'
export default {
  components: {
    Foot,
    Head,
    Dialog
  },
  data() {
    return {
      config: {
        top: '20vh',
        width: '500px',
        title: this.$fanyi('是否登录'),
        btnTxt: [this.$fanyi('前往登录'), this.$fanyi('关闭')],
      },
      index: -1,
      titlelist: [],
      list: [],
      id: '',
      playvedio: false,

    };

  },
  created() {
    this.getVideoSummary()


  },
  methods: {
    chat(e) {
      let element = document.getElementById("zsiq_float");
      // 创建事件
      var event = document.createEvent("MouseEvents");
      // 定义事件 参数： type, bubbles, cancelable
      event.initEvent("click", true, true);
      // 触发对象可以是任何元素或其他事件目标
      element.dispatchEvent(event);
      // if (this.$store.state.userInfo) {

      // } else {
      //   this.$refs.dialog.open(
      //     this.$fanyi('是否前往登录?'),
      //     () => {
      //       this.$store.commit('getactivePage', this.$route.fullPath)
      //       this.$fun.toPage('/login')

      //     },
      //     () => { }
      //   )
      // }
    },
    // 获取视频数据
    async getVideoSummary() {
      const res = await this.$api.getVideoSummary()

      if (res.code !== 0) {
        this.$message.error(res.msg);
        return
      }

      await res.data.forEach((i) => {

        if (i.video_list.length > 0) {
          i.video_list.forEach((ind) => {
            let str = ind.video_link.replace(/youtu.be/g, "www.youtube.com/embed");
            ind.url = str
            ind.load = true
          })
        } else {
          i.class_mark.forEach((ite) => {
            ite.video_list.forEach((item) => {
              let str = item.video_link.replace(/youtu.be/g, "www.youtube.com/embed");

              item.url = str
              item.load = true

            })
          })
        }

      })
      this.list = res.data
      this.titlelist = res.data

    },
    // 切换
    switchvedio(item, ind) {
      this.index = ind

      this.list = this.titlelist.reduce((acc, i) => {



        if (i.id == item.id) {
          // i.class_mark.forEach((item) => {

          //   item.video_list.forEach((vedioitem) => {
          //     vedioitem.load = true
          //   })
          // })
          acc.push(i)
        }
        return acc
      }
        , [])
    },

    allvedio() {

      this.list = this.titlelist
      this.index = -1
    },
    // onload
    onload(e, i) {

      i.load = false

    },
    // 视频播放点击
    async play(item) {


      if (item.id == this.id) {
        return
      } else {
        if (this.playvedio == true) {
          return
        }
        this.playvedio = true
        this.id = item.id
        var timer = setTimeout(() => {

          this.$api.videoClick({
            video_id: item.id
          })
          this.playvedio = false
        }, 1200);

      }



    }
  },
};
</script>
<style lang="scss" scoped>
.loader {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #ff730b;
  border-top-color: transparent;
  animation: spin 0.8s ease-in-out infinite;

  transition: opacity 0.3s ease-in-out;
}



@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.main {
  max-width: 1920px;
  margin: 0 auto;
  margin-top: -25px;
  padding-bottom: 81px;

  .content {
    position: relative;

    display: flex;
    justify-content: flex-end;
    width: 1400px;
    margin: auto;
    // min-height: 500px;

    .left—Video {
      margin-right: 40px;
      width: 280px;
      position:
        absolute;
      left: 0;


      border-radius: 10px;

      min-height: 0px;
      border: 1px solid #E2E2E2;
      padding-bottom: 20px;


      .vedio-title {
        padding-left: 20px;
        display: flex;
        margin-bottom: 10px;
        padding-top: 20px;

        img {
          width: 16px;
          height: 16px;
        }

        span {
          margin-left: 10px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #222222;
          line-height: 20px;


        }
      }

      .item {
        width: 280px;
        display: flex;
        padding-left: 20px;
        align-items: center;
        height: 40px;


        font-size: 14px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #222222;
        line-height: 20px;
      }

      .ativeitem {
        height: 40px;
        background: #FFF7F1;
        font-size: 14px;
        width: 100%;
        padding-left: 20px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #FF730B;
        line-height: 20px;
        position: relative;

        span {
          position: absolute;
          right: 0;
          width: 4px;
          height: 40px;
          background: #FF730B;
        }
      }
    }

    .right-box {
      width: 1080px;
      padding: 40px;
      background: #FFFFFF;
      min-height: 500px;

      border-radius: 10px;
      border: 1px solid #E2E2E2;

      .item {
        margin-bottom: 60px;

        .top-title {
          width: 100%;
          font-size: 18px;
          font-family: Roboto-Medium, Roboto;
          font-weight: 500;
          color: #222222;
          line-height: 26px;
        }

        .vediolist {
          width: 100%;
          padding-top: 20px;
          display: flex;
          flex-wrap: wrap;
          // flex-grow: wrap;
          // justify-content: space-between;

          .vedioitem {
            width: 320px;
            height: 280px;
            background: #FFFFFF;
            border-radius: 10px;
            border: 1px solid #E2E2E2;
            margin-right: 18px;
            margin-bottom: 20px;

            &:nth-child(3n) {
              margin-right: 0;
            }

            .vid-box {
              width: 320px;
              height: 180px;
              border-radius: 10px 10px 0px 0px;
              position: relative;

              .loading {
                width: 320px;
                height: 180px;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                left: 0;
                top: 0;
                background-color: black !important;

              }
            }

            .bigVideo {
              width: 319px;
              height: 180px;
              border-radius: 9px 10px 0px 0px;
            }

            .text {
              padding-left: 15px;
              font-size: 16px;
              font-family: Roboto-Medium, Roboto;
              font-weight: 500;
              color: #222222;
              line-height: 24px;
              height: 48px;
              margin-top: 15px;
              margin-bottom: 7px;
            }

            .footer {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;

              .left-pic {

                padding-left: 15px;
                display: flex;

                img {
                  margin-top: 1px;
                  width: 16px;
                  height: 16px;
                  margin-right: 5px;
                }

                div {
                  font-size: 12px;
                  font-family: Roboto-Regular, Roboto;
                  font-weight: 400;
                  color: #666666;
                  line-height: 18px;

                  span {
                    font-size: 12px;
                    font-family: Roboto-Regular, Roboto;
                    font-weight: 400;
                    color: #666666;
                    line-height: 18px;
                    margin-right: 5px;
                  }
                }
              }

              .right {
                padding-right: 20px;
                font-size: 12px;
                font-family: Roboto-Regular, Roboto;
                font-weight: 400;
                color: #666666;
                line-height: 18px;
              }
            }
          }
        }

        .listitem {
          margin-bottom: 30px;

          .listitem-title {
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;

            line-height: 20px;
            border-bottom: 1px solid #E2E2E2;
          }


        }
      }

    }
  }


}
</style>
